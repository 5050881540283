const urls = {
  railcars: 'objectInfo/railway.last_dislocation_data',
  vehicles: 'objectInfo/telemetry.vehicles',
  boat: 'objectInfo/telemetry.barges'
}

const cargoConfigs = {
  include: {
    cargo_places: {
      only: ['id', 'name', 'weight_net', 'weight_gross', 'volume', 'description', 'oversize', 'storage_condition', 'vehicle_id', 'receiving_date_fact', 'trip_id'],
      include: {
        vehicle: {
          only: ['id', 'reg_number']
        }
      }
    }
  }
}

const configs = {
  railcars: {
    only: ['id', 'carriage_no', 'current_station'],
    include: {
      cargoes: {
        ...cargoConfigs
      }
    }
  },
  vehicles: {
    only: ['id', 'reg_number'],
    include: {
      cargoes: {
        ...cargoConfigs
      }
    }
  },
  boat: {
    only: ['id', 'number'],
    include: {
      cargoes: {
        ...cargoConfigs
      }
    }
  }
}

export const getConfigByType = type => {
  return {
    config: configs[type],
    url: urls[type]
  }
}
