export const types = [
  {
    id: 'railcars',
    type: 'railcars',
    title: 'Вагон',
    url: 'cargo?format=railcars_reload_list',
    key: 'carriage_no'
  },
  {
    id: 'vehicles',
    type: 'vehicles',
    title: 'ТС',
    url: 'cargo?format=vehicles_reload_list',
    key: 'reg_number'
  },
  {
    id: 'boat',
    type: 'boat',
    title: 'Баржи',
    url: 'cargo?format=boat_reload_list',
    key: 'number'
  }
]

export const statusList = {
  waiting: {
    title: 'Ждет разгрузку',
    color: 'accent-primary',
    order: 1
  },
  unloading: {
    title: 'На разгрузке',
    color: 'accent-warning',
    order: 2
  },
  unloaded: {
    title: 'Разгружен',
    color: 'accent-success',
    order: 3
  }
}

export const related = {
  vehicle_types: {
    id: 1,
    urlName: 'vehicleTypes',
    data: [],
    config: { only: ['name', 'id'] }
  },
  main_contractor_id: {
    id: 2,
    urlName: 'mainContractor',
    data: [],
    config: { only: ['name', 'id'] }
  },
  vehicle_models: {
    id: 3,
    urlName: 'vehicleModels',
    data: [],
    config: { only: ['name', 'id'] }
  },
  subprojects: {
    id: 9,
    urlName: 'subprojects',
    data: [],
    config: { only: ['name', 'id'] }
  },
  subsubprojects: {
    id: 9,
    urlName: 'subsubprojects',
    data: [],
    config: { only: ['name', 'id'] }
  }
}

export const vehicleModel = [
  {
    model: 'reg_number',
    title: 'Номер ТС',
    type: 'input'
  },
  {
    model: 'driver_full_name',
    title: 'ФИО водителя',
    type: 'input'
  },
  {
    model: 'driver_phone',
    title: 'Телефон водителя',
    type: 'input'
  },
  {
    model: 'model_id',
    title: 'Марка, модель транспортного средства',
    type: 'select',
    filterable: true,
    select: 'vehicle_models'
  },
  {
    model: 'vehicle_type_id',
    filterable: true,
    title: 'Вид ТС',
    type: 'select',
    select: 'vehicle_types'
  },
  {
    model: 'main_contractor_id',
    title: 'Наименование подрядной / субподрядной организации',
    type: 'select',
    select: 'main_contractor_id',
    filterable: true
  }
]

export const cargoModel = [
  {
    model: 'packing_list_no',
    title: '№ Упаковочного листа',
    width: 'half',
    type: 'input'
  },
  {
    model: 'project',
    title: 'Проект',
    width: 'half',
    type: 'input'
  },
  {
    model: 'subproject_id',
    title: 'Подпроект',
    width: 'half',
    type: 'select',
    select: 'subprojects'
  },
  {
    model: 'subsubproject_id',
    title: 'Подподпроект',
    width: 'half',
    type: 'select',
    select: 'subsubprojects'
  },
  {
    model: 'external',
    title: 'Внешняя поставка',
    width: 'half',
    type: 'boolean'
  },
  {
    model: 'name',
    title: 'Вид номенклатуры',
    width: 'half',
    type: 'input'
  },
  {
    model: 'nomenclature_group',
    title: 'Группа номенклатуры',
    width: 'half',
    type: 'input'
  },
  {
    model: 'transport_doc_no',
    title: '№ транспортного документа',
    width: 'half',
    type: 'input'
  },
  {
    model: 'shipment_place',
    title: 'Место отгрузки',
    width: 'half',
    type: 'input'
  },
  {
    model: 'terms_of_delivery',
    title: 'Условия поставки',
    width: 'half',
    type: 'input'
  },
  {
    model: 'invoice_no',
    title: '№ инвойса',
    width: 'half',
    type: 'input'
  },
  {
    model: 'carriage_no',
    title: '№ вагона',
    width: 'half',
    type: 'input'
  },
  {
    model: 'container_no',
    title: '№ контейнера',
    width: 'half',
    type: 'input'
  },
  {
    model: 'tth',
    title: 'Номер ТТН',
    width: 'half',
    type: 'input'
  },
  {
    model: 'tn_date',
    title: 'Дата ТТН/УЛ',
    width: 'half',
    type: 'date',
    format: 'dd.MM.yyyy'
  },
  {
    model: 'sending_date',
    title: 'Дата Отгрузки, План',
    width: 'half',
    type: 'date',
    format: 'dd.MM.yyyy HH:mm'
  },
  {
    model: 'sending_date_fact',
    title: 'Дата отгрузки, Факт',
    width: 'half',
    type: 'date',
    format: 'dd.MM.yyyy HH:mm'
  },
  {
    model: 'est_delivery_date',
    title: 'Плановый срок доставки (Систем.)',
    width: 'half',
    type: 'date',
    format: 'dd.MM.yyyy HH:mm',
    read_only: true
  },
  {
    model: 'receiving_date_fact',
    title: 'Дата доставки, Факт',
    width: 'half',
    type: 'date',
    format: 'dd.MM.yyyy HH:mm'
  },
  {
    model: 'receiving_date',
    title: 'Плановый срок доставки (1сСОУ)',
    width: 'half',
    type: 'date',
    format: 'dd.MM.yyyy HH:mm'
  },
  {
    model: 'user_delivery_date',
    title: 'Плановый срок доставки (Польз.)',
    width: 'half',
    type: 'date',
    format: 'dd.MM.yyyy HH:mm'
  },
  {
    model: 'shipping_type',
    title: 'Тип перевозки',
    read_only: true,
    width: 'half',
    type: 'input'
  },
  {
    model: 'rfq_number',
    title: '№ RFQ',
    width: 'half',
    type: 'input'
  },
  {
    model: 'scn',
    title: 'SCN номер',
    width: 'half',
    type: 'input'
  },
  {
    model: 'title_number',
    title: 'Номер титула',
    width: 'half',
    type: 'input'
  },
  {
    model: 'zp_number',
    title: 'ЗП номер',
    width: 'half',
    type: 'input'
  },
  {
    model: 'places_num',
    title: 'Кол-во грузовых мест',
    type: 'number',
    width: 'full'
  },
  {
    model: 'weight_gross',
    title: 'Вес брутто, кг',
    width: 'third',
    type: 'number'
  },
  {
    model: 'weight_net',
    title: 'Вес нетто, кг',
    width: 'third',
    type: 'number'
  },
  {
    model: 'volume',
    title: 'Объём, м3',
    width: 'third',
    type: 'number'
  },
  {
    model: 'comment',
    title: 'Комментарий',
    width: 'full',
    type: 'input'
  }
]
