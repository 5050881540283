<template>
  <div
    v-if="activeItem"
    v-loading="loading"
    class="unload-content"
  >
    <unload-content-header
      :cargo="activeData"
      :clone="cloneData"
      @save="save"
      @cancel="reset"
      @open-create-vehicle="showCreate = true"
    />
    <unload-content-wrapper
      :vehicles-list="vehiclesList"
      :cargo="activeData"
    />
    <unload-create-vehicle
      v-if="showCreate"
      :is-visible="showCreate"
      @update-vehicles="loadVehicles"
      @close="showCreate = false"
    />
  </div>
  <unload-content-welcome v-else />
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { getConfigByType } from '../../helpers'
import { related } from '../../configs'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    unloadContentHeader: () => import('./unload-content-header'),
    unloadContentWrapper: () => import('./unload-content-wrapper.vue'),
    UnloadContentWelcome: () => import('./unload-content-welcome.vue'),
    UnloadCreateVehicle: () => import('./unload-create-vehicle.vue')
  },
  data() {
    return {
      loading: false,
      showCreate: false,
      related,
      vehiclesList: [],
      activeData: {},
      cloneData: {}
    }
  },
  computed: {
    activeItem() {
      return this.$store.state.unload.activeItem || null
    }
  },
  watch: {
    activeItem() {
      this.updateData()
    }
  },
  async created() {
    try {
      this.loading = true

      await this.loadRelated()
      await this.loadVehicles()
      await this.loadData()
    } catch (e) {
      throw new Error(e)
    } finally {
      this.loading = false
    }
  },
  methods: {
    async loadRelated() {
      for (const r in this.related) {
        const urlName = this.related[r].urlName
        const source_id = this.$store.state.services[urlName]

        if (!source_id) return

        try {
          const config = cloneDeep(this.related[r].config)
          const { data } = await this.$store.dispatch('GET_REQUEST', {
            url: `objectInfo/${source_id}?config=${JSON.stringify(config)}`
          })

          this.related[r].data = Object.values(data)
        } catch (e) {
          throw new Error(e)
        }
      }
      this.$store.commit('UNLOAD_SET_FIELD', ['related', this.related])
    },
    async loadVehicles() {
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: 'cargo?format=assignment_vehicle_list'
        })

        this.vehiclesList = data || []
      } catch (e) {
        throw new Error(e)
      }
    },
    async updateData() {
      try {
        this.loading = true
        await this.loadData()
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async loadData() {
      try {
        if (!this.activeItem?.id) {
          this.activeData = {}

          return
        }

        const { config, url } = getConfigByType(this.activeItem.type)
        const where = [
          { field: 'id', op: '=', value: this.activeItem.id }
        ]

        config.where = where

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `${url}?config=${JSON.stringify(config)}`
        })

        this.activeData = {
          ...data?.[this.activeItem.id],
          type: this.activeItem.type
        }
        this.cloneData = cloneDeep(this.activeData)
      } catch (e) {
        throw new Error(e)
      }
    },
    reset() {
      this.activeData = cloneDeep(this.cloneData)
    },
    async save() {
      try {
        this.loading = true

        const data = this.getChangedData()

        await this.$store.dispatch('POST_REQUEST', {
          url: 'objectInfo/telemetry.cargo_places',
          data
        })

        await this.loadData()

        this.$store.commit('UNLOAD_SET_FIELD', ['updateList', true])
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    getChangedData() {
      return this.activeData.cargoes.reduce((a, c, ind) => {
        const clone = this.cloneData.cargoes[ind]

        const changedCP = c.cargo_places.filter((cp, i) => {
          const curVehicleId = cp.vehicle_id || null
          const cloneVehicleId = clone.cargo_places[i]?.vehicle_id || null

          return curVehicleId !== cloneVehicleId || !!cp.receiving_date_fact
        })?.map(cp => ({ id: cp.id, vehicle_id: cp.vehicle_id, trip_id: cp.trip_id || uuidv4(), receiving_date_fact: cp.receiving_date_fact }))

        a.push(...changedCP)

        return a
      }, []) || []
    }
  }
}
</script>

<style lang="scss">
.unload-content {
  display: grid;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-content: flex-start;
}
</style>
